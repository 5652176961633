.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#flaf-token-presale {
  max-width: 850px; 
  display: flex; 
  overflow: hidden; 
  font-family: 'ABeeZee', sans-serif; 
  margin: auto;
}

#left-column, #right-column {
  padding: 20px; 
  box-sizing: border-box;
}

#left-column {
  width: 470px;
}

#right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ph1 {
  font-size: 20px; 
  color: #FFF; 
  margin: 30px 0;
  font-family: 'ABeeZee', sans-serif;
}

#progress-bar-container {
  position: relative; 
  margin: 20px 0;
}

#progress-bar {
  background-color: rgba(24,37,71,0.6); 
  border-radius: 10px; 
    border: 4px;
  height: 20px; 
  width: 100%;
}

#progress {
  background-color: #6a7214; 
  border-radius: 10px; 
  height: 100%; 
  width: 50%; /* Adjust this width to reflect the actual progress */
  transition: width 0.3s;
}

#sol-raised, #sol-target {
  position: absolute; 
  bottom: -25px; 
  font-size: 12px; 
  color: rgb(255,255,255);
  font-family: 'ABeeZee', sans-serif;
}

#sol-raised {
  left: 0;
}

#sol-target {
  right: 0;
}

#input-container {
  display: flex; 
  justify-content: space-between; 
  margin-bottom: 20px;
}
.input-box {
  position: relative;
  padding: 10px;
  margin-right: 10px; /* Add space between boxes */
  border: 1px solid #CCC;
  border-radius: 10px;
  background: rgba(255,255,255,0.65);
  flex-grow: 1; /* Boxes will grow to use available space */
  min-width: 200px; /* Minimum width to ensure they don't get too small */
    max-width: 200px;
  display: flex;
  flex-direction: column;
  height: 110px;
}

.input-box:last-child {
  margin-right: 0; /* No margin for the last box */
}

.input-box > div:first-child {
  display: flex;
  justify-content: space-between;
}

.input-box label,
.input-box .currency {
  font-size: 12px;
  color: rgb(27, 0, 82);
}

.input-box .usd-cost {
  font-size: 11px;
  color: rgb(27, 0, 82);
  text-align: right;
  position: relative;
}

.input-box .usd-value {
  font-size: 11px;
  color: rgb(27, 0, 82);
  position: absolute;
  right: 10px; /* Aligned to the right padding of the box */
  top: 22px; /* Increased space to prevent overlap */
}

.input-box .currency {
  font-size: 16px;
  font-weight: bold;
  color: rgb(27, 0, 82);
  position: absolute;
  bottom: 10px;
  left: 10px;
}

#sol-amount, #flaf-amount {
  background: transparent;
  border: none;
  outline: none;
  text-align: right;
  font-size: 16px;
  color: rgb(27, 0, 82);
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 70%; /* Adjust width to prevent the input from being too narrow */
}


#buy-tokens, #connect-wallet {
  background-color: rgb(27, 0, 82);
  color: rgb(254, 254, 243);
  border-radius: 30px;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 23px;
  border: 3px solid rgba(255,255,255);
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  transition-duration: 0.2s;
}

#buy-tokens:hover, #connect-wallet:hover {
  border: 3px solid transparent;
}

#buy-tokens {
  width: 100%;
}

#connect-wallet {
  width: 60%;
  align-self: flex-end;
}

#token-info {
  background: rgba(13,3,33,0.79); 
  border-radius: 10px; 
  padding: 19px; 
  font-size: 12px; 
  color: rgb(255,255,255);
  overflow-y: auto;
  margin-top: 20px;
  min-height: 80%;
}

#wallet-status {
  text-align: center; 
  margin-top: 10px; 
  font-size: 12px; 
  color: #FFF;
  text-decoration: underline;
}